import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`High (pocket) Hang Squat Clean 4×4\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`1-10 reps each:`}</p>
    <p>{`Power Clean (135/95)(RX+=155/105)`}</p>
    <p>{`Burpee Box Jumps (box facing, 24/20″)`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      